import { max } from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSwiper } from 'swiper/react';

export const AboutSliderBtns = ({ Pagination }) => {
  const swiper = useSwiper();
  const navigate = useNavigate();

  function handleSwipe() {
    if (swiper.slideNext()) {
      swiper.slideNext();
    } else {
      navigate(`/home`);
    }
  }

  return (
    <div className="position-absolute w-100 ">
      <div className="swiper-nav-btns mx-3 mt-3 d-flex justify-content-between">
        <button
          className="btn rounded-5 fs-5"
          onClick={() => swiper.slidePrev()}
        >
          Previous
        </button>

        <button
          className="btn btn-primary w-25 fs-5 rounded-5"
          onClick={() => handleSwipe()}
        >
          Next
        </button>
      </div>
    </div>
  );
};
