import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import "../App.css";
export default function Location() {
  const navigate = useNavigate();
  const [defaultBtn, setDefaultBtn] = useState(false);

  return (
    <>
      <div className="location-contain d-flex flex-column">
        <div className="d-flex justify-content-center">
          <h4 className="fw-bold">
            Good Evening <span className="locationText">Sir</span>{" "}
          </h4>
          <i class="fa-solid fa-hands-clapping"></i>
        </div>
        <div>
          <div className="locationText h6 p-3 mb-3 fw-bold">My Locations</div>
        </div>
        <div className="locationCard mx-4 bg-light rounded-4 p-3">
          <button className="deleteBtn">
            <i class="fa-solid fa-trash"></i>
          </button>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <button className="btn btn-primary w-auto btn-sm rounded-5">
              Default
            </button>
            <div className="mt-3">
              <h5 className="fw-bold">Al Ain</h5>
              <span className="textLight">Location Details Here</span>
            </div>
          </div>
        </div>
        <div className="locationCard mx-4 bg-light rounded-4 p-3">
          <button className="deleteBtn">
            <i class="fa-solid fa-trash"></i>
          </button>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {defaultBtn ? (
              <button className="btn btn-primary w-25 btn-sm rounded-5">
                Default
              </button>
            ) : (
              <button className="btn btn-outline-warning w-auto btn-sm rounded-5">
                Make Default
              </button>
            )}

            <div className="mt-3">
              <h5 className="fw-bold">Dubai</h5>
              <span className="textLight">Location Details Here</span>
            </div>
          </div>
        </div>
        {/* <div> */}
        <div className="btn btn-primary m-auto">Add New</div>
        {/* </div> */}
      </div>
    </>
  );
}
