import React from 'react'
import './HeaderV3.css'
import { IoIosArrowBack } from "react-icons/io";
const HeaderV3 = ({title,onPress}) => {
  return (
    <div className='head_v3'>
      <div className='back_arrow'>
        <IoIosArrowBack
          onClick={onPress}
        />
      </div>
      <div className='notification-bar'>
        Notification Bar
      </div>
    </div>
  )
}

export default HeaderV3
