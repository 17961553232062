import React, { useEffect, useState } from "react";
// import "./hotoffers.css";
import axios from "axios";
import { default as Pusher, default as pusherJs } from "pusher-js";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Countdown from "react-countdown";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../HomeOpern/Offers/Offers";
import "../../HomeOpern/homeopen.css";
import Modal from "./modal";
import "./newHotOffers.css";

const HotOffers = () => {
  const navigate = useNavigate();
  const [offers, setoffers] = useState([]);
  const [products, setProducts] = useState([]);
  const [pageloading, setpageloading] = useState(true);
  const language = useSelector((state) => state.language.lang);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState(
    localStorage.getItem("manjamUser")
      ? JSON.parse(localStorage.getItem("manjamUser"))
      : null
  );
  useEffect(() => {}, []);
  const notifyMe = (id) => {
    setNotifyLoading(true);
    const data_send = {
      user_id: userData?.userId,
      offer_id: id,
    };
    axios
      .post("https://api.manjam.shop/v4/offers/notifyMe", data_send)
      .then((res) => {
        toast.success(
          language == "ar"
            ? "!.شكرا, سوف نخبرك"
            : "Thanks, We will notify you.!"
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setNotifyLoading(false);
      });
  };
  const getProducts = async () => {
    const data_send = {
      type: "user",
      user_id: userData?.userId,
    };
    await axios
      .post("https://api.manjam.shop/v4/offers/select_offers", data_send)

      .then((res) => {
        // console.log(res.data.message);
        if (Array.isArray(res.data.message)) {
          // console.log(res?.data?.message);
          setoffers(res?.data?.message.filter((item) => item.isTendered == 1));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setpageloading(false);
      });
  };

  const getOffers = async () => {
    const data_send = {
      type: "user",
      user_id: userData?.userId,
    };
    await axios
      .post("https://api.manjam.shop/v4/offers/select_offers", data_send)

      .then((res) => {
        // console.log(res.data.message);
        if (Array.isArray(res.data.message)) {
          // console.log(res?.data?.message);
          setProducts(
            res?.data?.message.filter((item) => item.isTendered == 0)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setpageloading(false);
      });
  };
  useEffect(() => {
    getProducts();
    getOffers();
  }, []);
  const [joinLoading, setJoinLoading] = useState(false);
  useEffect(() => {
    let requestInProgress = false;

    async function fetchData() {
      try {
        pusherJs.logToConsole = false;

        var pusher = new Pusher("d207d6ba54517230b8ab", {
          cluster: "ap2",
        });
        var channel = pusher.subscribe("my-channel");

        if (!requestInProgress) {
          requestInProgress = true;
          // Make the request to get products
          await getProducts();
          // toast.warn(language == "ar" ? "بدأت المناقصة" : "Terndered Started");
          setJoinLoading(false);
          requestInProgress = false;
          channel.bind("NewJoiner", async function (data) {
            // console.log(data);
            await getProducts();
          });
          // Bind Pusher event handlers after the request has completed
          channel.bind("startTender", async function (data) {
            if (!requestInProgress) {
              requestInProgress = true;
              await getProducts();

              setJoinLoading(false);
              requestInProgress = false;
            }
          });
          channel.bind("priceReduced", async function (data) {
            if (!requestInProgress) {
              requestInProgress = true;
              await getProducts();
              setJoinLoading(false);
              requestInProgress = false;
            }
          });

          function handleDisconnection() {
            pusher.connect();
          }

          pusher.connection.bind("disconnected", handleDisconnection);
        }
      } catch (e) {
        console.log(e);
      }
    }

    // Call fetchData function only once after the component mounts
    fetchData();
  }, [pusherJs]);

  const joinRoom = (coins, roomId, offer_id) => {
    setJoinLoading(true);
    axios
      .post("https://api.manjam.shop/v4/rooms/join", {
        user_id: userData?.userId,
        user_image: userData?.userPicture,
        user_name: userData?.username,
        cost: coins,
        offer_id: offer_id,
        rooms_id: roomId,
        store: parseInt(userData?.storeId) == 2 ? "ksa" : "uae",
      })
      .then((res) => {
        if (res?.data?.status) {
          toast.success(
            language == "ar" ? "تم الالتحاق بنجاح" : "Joined SuccessFully"
          );
          setModal(false);
          setJoinLoading(false);
        } else {
          toast.error(language == "ar" ? "فشل الالتحاق" : "Joined Failed");
          setJoinLoading(false);
        }
      })
      .catch((err) => err)
      .finally(() => {
        setJoinLoading(false);
      });
  };

  const renderer = ({
    total,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    completed,
  }) => {
    if (completed) {
      // getProducts();
      return null;
    } else {
      // Render a countdown
      <span>
        {hours}:{minutes}:{seconds}
      </span>;
      return (
        <>
          {days > 0 ? (
            <>
              <div>
                <h6 style={{ color: "#7f19a8" }}>
                  {days < 10 ? "0" + days : days}
                </h6>
                <h5>days</h5>
              </div>
              <span>:</span>
            </>
          ) : null}
          <div>
            <h6 style={{ color: "#7f19a8" }}>
              {hours < 10 ? "0" + hours : hours}
            </h6>
            <h5>Hours</h5>
          </div>
          <span>:</span>
          <div>
            <h6 style={{ color: "#7f19a8" }}>
              {minutes < 10 ? "0" + minutes : minutes}
            </h6>
            <h5>Mins</h5>
          </div>
          {days <= 0 ? (
            <>
              <span>:</span>
              <div>
                <h6>{seconds < 10 ? "0" + seconds : seconds}</h6>
                <h5>Sec</h5>
              </div>
            </>
          ) : null}
        </>
      );
    }
  };
  // useEffect(() => {
  //   function setProgress(percentage) {
  //     if (document.querySelector(".circle-progress")) {
  //       const circleProgress = document.querySelector(".circle-progress");
  //       const countText = document.querySelector(".circle-text .count");

  //       // Convert the percentage to degrees (0% = 0deg, 100% = 180deg)
  //       const degrees = (percentage / 100) * 180;

  //       // Apply rotation to show the progress
  //       circleProgress.style.transform = `rotate(${degrees}deg)`;

  //       // Update the text to show the percentage
  //       countText.textContent = `${percentage}%`;
  //     }
  //   }

  //   // Example: Set progress to 75%
  //   setProgress(75);
  // }, [document]);

  return (
    <div className="offerPage">
      <div className="open_offers">
        <div className="offers_title">
          <h3>{language === "ar" ? "العطاءات" : "Hot Biddings"}</h3>
          <h6
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/otherof")}
          >
            {language === "ar" ? "مشاهدة الكل" : "Explore All"}
          </h6>
        </div>
      </div>
      {pageloading ? (
        <div>
          <Skeleton count={10} />
        </div>
      ) : offers && offers.length > 0 ? (
        offers.map((item, index) => {
          const isTender = item?.isTendered;
          const joined = item?.joiners?.filter((item) => {
            return item?.user_id?.toString() === userData?.userId?.toString();
          })?.length;
          const tenderNotBegin =
            item.time_av_after.days >= 0 &&
            item.time_av_after.hours >= 0 &&
            item.time_av_after.minutes >= 0 &&
            item.time_av_after.seconds >= 0;
          if (isTender)
            return (
              <div
                className={
                  tenderNotBegin
                    ? "offer_card"
                    : "offer_card offer_card_without_counter"
                }
                onClick={() => {
                  // navigate("/productdetaisldes",{state:{productdata:item,id:item.products[0].id}})
                }}
              >
                {/* <div className="notify" onClick={() => notifyMe(item?.id)}>
                  {!notifyLoading ? (
                    <>
                      {" "}
                      <img src={require("../../../assets/notpil.png")} alt="" />
                      <h6>{language == "ar" ? "إضافة تنبيه" : "Notify Me"}</h6>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div> */}
                {/* <img
                  src={item?.products[0]?.colors[0]?.images[0]?.link}
                  alt=''
                /> */}

                <div className="offer_details">
    
                  <div className="d-flex aling-items-start gap-1 image-container">
                    <div className="image">
                      <img
                        onClick={() => {
                          navigate("/productdetails?offer_id=" + item?.id, {
                            state: {
                              id: item?.products[0]?.id,
                              offer: item,
                            },
                          });
                        }}
                        src={item?.products[0]?.colors[0]?.images[0]?.link}
                        alt=""
                      />

                      {tenderNotBegin ? (
                        <div className="spinnerContain">
                          <div className="spinner">
                            <div class="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="soonDiv" style={{ color: "black" }}>
                              Soon
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <div className="d-flex  flex-price flex-price2">
                        <del>
                          {" "}
                          AED
                          {
                            item?.products[0]?.colors[0]?.props[0]?.values[0]
                              ?.old_price
                          }
                        </del>
                        <div class="ribbon">
                          <span class="ribbon5">
                            {" "}
                            <span className="">AED</span>
                            <span className="fw-bolder">
                              {
                                item?.products[0]?.colors[0]?.props[0]
                                  ?.values[0]?.new_price
                              }
                            </span>{" "}
                          </span>
                        </div>
                      </div>
                      
                      <div className="offertitle_container">
                      <p
                        className="offertitle"
                        style={{
                          color: "#62008a",
                          marginBottom: "10px",
                          fontWeight: "bolder",
                          textAlign: "start",
                          // marginTop:"60px"
                        }}
                      >
                        {language === "ar"
                          ? item?.products[0]?.title_ar
                          : item?.products[0]?.title}
                      </p>
                      <h6 className="offer_description blured">
                        {language === "ar"
                          ? item?.products[0]?.description_ar
                          : item?.products[0]?.description}
                      </h6>
                      </div>

                    </div>
                  </div>

                  <hr />

                  <div className="text-center av_date">
                    will Be End On{" "}
                    {item?.will_av_for
                      ?.split("T")[0]
                      ?.split("-")
                      ?.join("  /  ")}
                  </div>

                  <div className="text-end">
                    <del>
                      AED{" "}
                      {
                        item?.products[0]?.colors[0]?.props[0]?.values[0]
                          ?.old_price
                      }
                    </del>
                  </div>
                  <div class="newoffer-container">
                    <div class="wishlist">
                      <i class="fas fa-heart"></i>
                      <p>Add to Wish list</p>
                    </div>
                     
                    <div class="progress-circle-2">
                        <div class="circle-2" style={{backgroundImage:`conic-gradient(#7f19a8 0% ${(item?.joiners?.length/item?.room?.max_members)*100}%, transparent 0%)`}}>
                          <div class="circle-inner-2">
                            <div class="circle-text-2">
                              <span class="label-2">JOINERS</span>
                              <span class="number-2">{item?.joiners?.length}{language=="ar"?"من":"of"}  {item?.room?.max_members}</span>
                              {/* <span class="sub-label-2">OF</span> */}
                            </div>
                          </div>
                        </div>
                      </div>

                    {/* <div class="joiners">
                      <div class="progress">
                        <div className="joinProgress">
                          <p class="joiners_number">{item?.joiners?.length}</p>
                          <p className="first_title">Joiners</p>
                          <p className="first_out">out of</p>
                          <p className="first_max">{item?.room?.max_members}</p>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          width="180px"
                          height="180px"
                        >
                          <defs>
                            <linearGradient
                              id="GradientColor"
                              x1="0%"
                              y1="0%"
                              x2="100%"
                              y2="100%"
                            >
                              <stop offset="0%" stop-color="#DA22FF" />
                              <stop offset="90%" stop-color="#9733EE" />
                            </linearGradient>
                          </defs>
                          <circle
                            cx="80"
                            cy="80"
                            r="70"
                            stroke="url(#GradientColor)"
                            stroke-width="14"
                            strokeDasharray="439.82"
                            strokeDashoffset={
                              439.82 -
                              (item?.joiners?.length /
                                item?.room?.max_members) *
                                439.82
                            }
                            fill="none"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div> */}
                    <div class="buy-now">
                      <i class="fas fa-shopping-cart"></i>
                      <p>Buy Now</p>
                    </div>
                  </div>
                   

                  <div className="d-flex align-items-center justify-content-between my-3">
                  {tenderNotBegin ? (
                    <div
                      style={{ color: "#FFF" }}
                      className="notify"
                      onClick={() => notifyMe(item?.id)}
                    >
                      {!notifyLoading ? (
                        <>
                          {" "}
                          <img
                            src={require("../../../assets/notpil.png")}
                            alt=""
                          />
                          <h6>
                            {language == "ar" ? "إضافة تنبيه" : "Notify Me"}
                          </h6>
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  ) : isTender && !joined ? (
                    <>
                      <div
                        style={{ color: "#FFF" }}
                        className="notify"
                        onClick={() => {
                          if (userData?.coins >= item?.cost)
                            setModal([
                              item?.cost,
                              item?.room?.id ? item?.room?.id : "0",
                              item?.id,
                            ]);
                          else
                            toast.error(
                              language == "ar"
                                ? "ليس لديك نقاط كافية للالتحاق بالمناقصة"
                                : "You are not have enough points to join"
                            );
                        }}
                      >
                        {language === "ar" ? "اشترك الآن" : "Join Now"}
                        <div className="join-price">
                          {language === "ar" ? "مقابل $" : "for"}({item?.cost})
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ color: "#FFF" }}
                      className="notify"
                      onClick={() =>
                        navigate("/productdetails?offer_id=" + item?.id)
                      }
                    >
                      <> Buy Now</>
                    </div>
                  )}

<div className="offer_time offer_time_av_after">
                      {}{" "}
                      <Countdown
                        date={
                          tenderNotBegin
                            ? Date.now() + item?.time_av_after?.milliSeconds
                            : Date.now() + 0
                        }
                        renderer={renderer}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
        })
      ) : (
        <h4>No Offers</h4>
      )}

      <div className="open_offers">
        <div className="offers_title">
          <h3>{language === "ar" ? "العروض" : "Offers"}</h3>
          <h6
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/AllOffers")}
          >
            {language === "ar" ? "مشاهدة الكل" : "Explore All"}
          </h6>
        </div>
      </div>

      <div className="offersList">
        <Swiper slidesPerView={2} loop={true}>
          {products && products.length > 0
            ? products.map((item, index) => {
                if (index < 4)
                  return (
                    <SwiperSlide
                      className={`withOfferSlide ${ item.time_av_after.days >= 0 &&
                        item.time_av_after.hours >= 0 &&
                        item.time_av_after.minutes >= 0 &&
                        item.time_av_after.seconds >= 0 ? "withoutOfferslide":""}`}
                      onClick={() => {
                        return item.time_av_after.days <= 0 &&
                          item.time_av_after.hours <= 0 &&
                          item.time_av_after.minutes <= 0 &&
                          item.time_av_after.seconds <= 0
                          ? navigate("/productdetails?offer_id=" + item?.id, {
                              state: {
                                id: item?.products[0]?.id,
                                offer: item,
                              },
                            })
                          : null;
                      }}
                    >
                      <div
                        className={
                          item.time_av_after.days >= 0 &&
                          item.time_av_after.hours >= 0 &&
                          item.time_av_after.minutes >= 0 &&
                          item.time_av_after.seconds >= 0
                            ? "withOffer rounded-4 withOutOffer list withoutOffer"
                            : "withOffer rounded-4 withOutOffer list"
                        }
                      >
                        <div className="offerImg w-25 mx-1">
                          <img
                            src={item?.products[0]?.colors[0]?.images[0]?.link}
                            className=""
                            alt=""
                          />
                          {item.time_av_after.days >= 0 &&
                          item.time_av_after.hours >= 0 &&
                          item.time_av_after.minutes >= 0 &&
                          item.time_av_after.seconds >= 0 ? (
                            <div className="spinnerContain">
                              <div className="spinner">
                                <div class="lds-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                <div className="soonDiv soonDivSecond">
                                  Soon
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="mx-1 px-1 py-2">
                          <h6 className=" p-0">
                            {language == "ar"
                              ? item?.products[0]?.title_ar
                                  .split(" ")
                                  .slice(0, 3)
                                  .join(" ")
                              : item?.products[0]?.title
                                  .split(" ")
                                  .slice(0, 3)
                                  .join(" ")}{" "}
                            . . .
                          </h6>
                          <p className="p-0">
                            {language == "ar"
                              ? item?.products[0]?.description_ar
                                  .split(" ")
                                  .slice(0, 3)
                                  .join(" ")
                              : item?.products[0]?.description
                                  .split(" ")
                                  .slice(0, 3)
                                  .join(" ")}{" "}
                            . . .
                          </p>
                          <hr />
                          <div className="d-flex align-items-center gap-2 mb-1">
                          
                            <h6 className="text-muted small">
                              {" "}
                              $
                              <del>
                                {
                                  item?.products[0]?.colors[0]?.props[0]
                                    ?.values[0]?.old_price
                                }
                              </del>
                            </h6>
                            <h6 className="priceClr">
                              {
                                item?.products[0]?.colors[0]?.props[0]
                                  ?.values[0]?.new_price
                              }
                              $
                              
                            </h6>
                          </div>

                          <div className="hotoffer_time_container d-flex gap-4 justify-content-between">
                            <div className="offer_time">
                              <Countdown
                                date={
                                  Date.now() + item?.time_av_after?.milliSeconds
                                }
                                renderer={renderer}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
              })
            : null}
        </Swiper>
      </div>
      <Modal
        visible={modal}
        onClose={setModal}
        title={language == "ar" ? "الالتحاق بالغرفة" : "Join Room"}
      >
        <>
          <p style={{ margin: "10px", padding: "10px" }}>
            {language == "ar"
              ? `

يجب على المتقدمين تقديم عروضهم بشكل كامل وفي الموعد المحدد دون أي تأخير.
يتعين على المتقدمين تقديم ضمان بنكي صالح للفترة المناسبة وبمبلغ محدد.
يجب أن تتوافق الشركات المتقدمة مع المعايير الفنية والمتطلبات القانونية المحددة في الإعلان.
يتوجب على المتقدمين تقديم وثائق تثبت خبرتهم وقدراتهم الفنية والمالية لتنفيذ المشروع بنجاح.`
              : `The solutions must be fully advanced using the authority tool without any delay.
Applicants must provide bank guarantees to suit the intended orientation and in a specified amount.
There must be specific requirements in the advertisement.
In particular, applicants must submit documents that indicate their effective financial and financial background and areas.`}
          </p>
          {joinLoading ? (
            <Loader />
          ) : (
            <button
              className="btn btn-success"
              onClick={() => {
                joinRoom(...modal);
              }}
            >
              {language === "ar" ? "اشترك الآن" : "Join Now"}
            </button>
          )}
        </>
      </Modal>
    </div>
  );
};

export default HotOffers;
