import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Pagination, Navigation, EffectFade } from 'swiper/modules';
import { AboutSliderBtns } from './AboutSliderBtns';

export default function App() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <>
      <Swiper
        pagination={pagination}
        grabCursor={true}
        navigation={true}
        className="mySwiper myaboutSwiper"
        effect={'fade'}
        modules={[EffectFade, Pagination, Navigation]}
      >
        <SwiperSlide>
          <div className="AboutSlider bg-1"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="AboutSlider bg-2"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="AboutSlider bg-3"></div>
        </SwiperSlide>

        <AboutSliderBtns />
      </Swiper>
    </>
  );
}
