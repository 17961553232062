import React, { useEffect, useState } from 'react';
import Slider from './Slider/Slider';
import HomeCategories from './HomeCategories/HomeCategories';
import HotOffers from './HotOffers/HotOffers';
import Header from '../header';
import './home.css';
import HomeHeader from './HomeHeader/HomeHeader';
import axios from 'axios';
import HomeSearch from './HomeSearch/HomeSearch';
import PagesButtons from '../PagesButtons/PagesButtons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';

const Home = () => {
  const [showModal , setShowModal] = useState(false);
  const [sitedata, setsitedata] = useState({});
  const getsitedata = () => {
    axios
      .get("https://api.manjam.shop/v4/site/info/getAll")
      .then((res) => {
        // console.log(res.data?.message);
        setsitedata(res?.data?.message[0]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getsitedata();
  }, []);

  useEffect(() => {
    console.log(sitedata)
  } , [sitedata])

  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  return (
    <>
    <Modal style={{margin:"auto"}} size="350px" headerIcon="X" show={showModal} title={language == "ar" ?"نبذه عن منجم" :"About Mangam"} onClose={() => setShowModal(false)}>
       <p>{language == "ar"? sitedata.about_ar : sitedata.about}</p>
    </Modal>
    <div  className="home_page" style={{ marginBottom: '80px' }}>

      <div className="headerhome">
        {/* <Header/> */}
        <HomeHeader />
        {/* <button>Recharge balance</button> */}
      </div>
      <HomeSearch />
      <Slider />
      <HomeCategories />
      <HotOffers />
      {/* <div className="messimg">
        <img src={require("../../assets/chat 1.png")} alt="" />
      </div> */}
      <PagesButtons language={language} navigate={navigate}/>

      <div className='home-deals'>
         <p>Read about Manjam deals</p>
         <button onClick={() => setShowModal(true)}>Click Here</button>
      </div>
      </div>
      </>
  );
};

export default Home;
