// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_container {
  padding: 10px;
}

.search {
  display: flex;
  align-items: center;
  background-color: white;
  color: #7F19A8 !important;
  /* background-color: #faf7fa; */
  padding: 8px;
  border-radius: 99px;
  box-shadow: rgba(127, 25, 168 , 0.2) 0px 2px 8px 0px;
}

.search_container input::placeholder {
  color: #7F19A8 !important;
}

.search_icon {
  color: #7F19A8;
  font-weight: bolder;
  margin-right: 10px;
}

.search {
  display: flex;
  align-items: center;
}

input {
  /* width: 100%; */
  border: none;
  outline: none;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/HomeSearch/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,+BAA+B;EAC/B,YAAY;EACZ,mBAAmB;EACnB,oDAAoD;AACtD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,6BAA6B;AAC/B","sourcesContent":[".search_container {\r\n  padding: 10px;\r\n}\r\n\r\n.search {\r\n  display: flex;\r\n  align-items: center;\r\n  background-color: white;\r\n  color: #7F19A8 !important;\r\n  /* background-color: #faf7fa; */\r\n  padding: 8px;\r\n  border-radius: 99px;\r\n  box-shadow: rgba(127, 25, 168 , 0.2) 0px 2px 8px 0px;\r\n}\r\n\r\n.search_container input::placeholder {\r\n  color: #7F19A8 !important;\r\n}\r\n\r\n.search_icon {\r\n  color: #7F19A8;\r\n  font-weight: bolder;\r\n  margin-right: 10px;\r\n}\r\n\r\n.search {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\ninput {\r\n  /* width: 100%; */\r\n  border: none;\r\n  outline: none;\r\n  background-color: transparent;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
