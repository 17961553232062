import React from 'react'
import { IoSearchSharp } from "react-icons/io5";
import  "./style.css";


const HomeSearch = () => {
  return (
    <div className='search_container'>
      <div className='search'>
        <div className='search_icon'>
          <IoSearchSharp />
        </div>
        <input type="text" placeholder='Looking For Something' />
      </div>

    </div>
  )
}

export default HomeSearch
