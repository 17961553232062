// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pages_buttons_coantainer{
  padding: 10px;
}


.buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button_text{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  
}
.button_text .text{
  font-size: 10px;
  color: #222;
  font-weight: bolder;
}

.button{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #7f19a8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 0px -1px 6px #b1b1b1; ;
}`, "",{"version":3,"sources":["webpack://./src/components/PagesButtons/style.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,QAAQ;;AAEV;AACA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gCAAgC;AAClC","sourcesContent":["\r\n.pages_buttons_coantainer{\r\n  padding: 10px;\r\n}\r\n\r\n\r\n.buttons{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n}\r\n\r\n.button_text{\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  gap: 7px;\r\n  \r\n}\r\n.button_text .text{\r\n  font-size: 10px;\r\n  color: #222;\r\n  font-weight: bolder;\r\n}\r\n\r\n.button{\r\n  width: 60px;\r\n  height: 60px;\r\n  border-radius: 50%;\r\n  color: #7f19a8;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: 30px;\r\n  box-shadow: 0px -1px 6px #b1b1b1; ;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
